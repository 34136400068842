// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-occp-tsx": () => import("./../../../src/pages/all-occp.tsx" /* webpackChunkName: "component---src-pages-all-occp-tsx" */),
  "component---src-pages-all-oip-tsx": () => import("./../../../src/pages/all-oip.tsx" /* webpackChunkName: "component---src-pages-all-oip-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-occp-occp-markdown-remark-frontmatter-occp-tsx": () => import("./../../../src/pages/occp/occp-{MarkdownRemark.frontmatter__occp}.tsx" /* webpackChunkName: "component---src-pages-occp-occp-markdown-remark-frontmatter-occp-tsx" */),
  "component---src-pages-oips-oip-markdown-remark-frontmatter-oip-tsx": () => import("./../../../src/pages/oips/oip-{MarkdownRemark.frontmatter__oip}.tsx" /* webpackChunkName: "component---src-pages-oips-oip-markdown-remark-frontmatter-oip-tsx" */)
}

